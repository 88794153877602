$font-primary: 'Roboto', sans-serif;
// 400, 500, 700
$font-sm: 14px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 24px;
$font-xxl: 36px;

$white: #fff;
$black: #000;
$primary: #e84118;
$primary-tab:#1a6a9e;
$primary-light: #ACDDDE;
$border-gray: #d9d9d9;
$card-border: #e3e3e3;
$gray-text: #b1b1b1;
$gray-bg: #f9f9f9;
$gray-light: #EBEBEB;
$gray-medium: #F0F0F0;
$chat-bg: #e5ddd5;
$green: #019C58;
$black-500: #545454;
$black-400: #C4C4C4;
$yellow: #F8B623;
$orange: #FFC464;
$orange-dark: #774A00;
$orange-light: #FFF5EE;

$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 12px;