@import '/src/Variable.scss';

.peopleTabContent {
  .showFilterComponent {
    padding: 0;
    .filterLayout {
      margin: 0 0 10px 0;
      background: #eee;
      padding: 20px;
      border-radius: $border-radius-sm;
      .ant-row {
        .ant-col {
          h5 {
            font-family: $font-primary;
            font-weight: 400;
          }
          .ant-select {
            caret-color: transparent;
            .ant-select-selector {
              border-radius: $border-radius-sm;
              border: 1px solid $border-gray;
              height: 44px;
              padding: 0 15px;
              .ant-select-selection-item {
                display: flex;
                font-size: 16px;
                align-items: center;
                font-weight: 700;
              }
              .ant-select-selection-placeholder {
                display: flex;
                align-items: center;
                left: 15px;
              }
              
            }
            &.ant-select-multiple {
              .ant-select-selector {
                .ant-select-selection-overflow {
                  .ant-select-selection-overflow-item {
                    .ant-select-selection-item {
                      background-color: $primary-light;
                      border-radius: 100px;
                      padding: 10px 12px 10px 20px;
                      height: 30px;
                      .ant-select-selection-item-content {
                        font-weight: 400;
                      }
                      .ant-select-selection-item-remove {
                        color: $black;
                        font-size: 14px;
                        font-weight: 400;
                        margin: -2px 0 0 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .search_footer {
          display: flex;
          width: 100%;
          margin: 20px 0 0 0;
          font-size: 16px;
          .show_result_count {
            color: $gray-text;
          }
          .clear_search {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;
            svg {
              margin: -1px 5px 0 0;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .tabs {
    .ant-tabs-nav {
      height: 38px;
      &:before {
        border-bottom: 1px solid $border-gray;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          margin-bottom: 1px;
          .ant-tabs-tab {
            padding: 0 18px 0 18px;
            border-radius: 10px 10px 0 0;
            border: unset;
            background-color: transparent;
            border-bottom: 3px solid transparent;
            .ant-tabs-tab-btn {
              color: $black ;
              font-weight: bold;
              text-shadow: unset;
            }
            &.ant-tabs-tab-active {
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              border-bottom: 3px solid $primary;
              border-radius: 10px 10px 0 0;
              .ant-tabs-tab-btn {
                // color: $primary-tab !important;
                color: inherit;
                font-weight: bolder;
              }
            }
            
          }
        }
      }
    }
    .ant-tabs-content-holder {
      padding-top: 15px;
      .ant-tabs-content {
        min-height: calc(100vh / 2);
        .ant-tabs-tabpane {
          .peopleTab {
            .ant-empty {
              display: block;
              margin: 80px auto 100px auto;
              .ant-empty-image {
                height: 80px;
              }
              .ant-empty-description {
                font-size: 18px;
                margin: 10px 0 0 0;
                color: rgba($black, 0.4);
              }
            }
          }
        }
      }
    }
  }
  .inFilterButton, .inFilterButtonClicked {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: auto;
    font-size: $font-md;
    height: auto;
    color: $black !important;
    border: 1px solid $black !important;
    border-radius: $border-radius-sm;
    padding: 8px 16px;
    svg {
      height: 14px;
    }
    &:hover {
      color: $primary;
      border: 1px solid $primary;
    }
  }
  .inFilterButtonClicked {
    background-color: $primary !important;
    border: 1px solid $primary !important;
    color: $white !important;
    &:hover {
      color: $white;
      opacity: 0.9;
    }
  }
}
.ant-tabs-card {
  .ant-tabs-nav{
    .ant-tabs-tab-active{
      color: inherit !important;
    }
  }
}  
@media only screen and (max-width: 768px) {
  .peopleTabContent {
    padding: 0 20px 0 20px !important;
    .tabs {
      .ant-tabs-nav {
        margin: 0 0 27px 0;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              padding: 0 10px 0 10px;
              border-radius: 5px 5px 0 0 !important;
              .ant-tabs-tab-btn {
                font-size: 14px;
              }
            }
          }
        }
        .ant-tabs-extra-content {
          .inFilterButton, .inFilterButtonClicked {
            padding: 0 5px 0 5px;
            span {
              display: none;
            }
          }
        }
      }
      .ant-tabs-content-holder {
        padding-top: 0;
        .ant-tabs-content {
          .ant-tabs-tabpane {
            .peopleTab {

            }
          }
        }
      }
    }
    .inFilterButton, .inFilterButtonClicked {
      font-size: $font-sm;
    }
    .showFilterComponent {
      .filterLayout {
        .ant-row {
          .ant-col {
            h5 {
              font-size: 14px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}



  

// .peopleTabIn {
//   padding: 50px;
// }

// .filterButton {
//   padding-right: 100px;
//   font-style: italic;
// }

// .peopleTabIN {
// }

.peopleTabContent {
  padding: 0 120px;
}

// .buttonIN {
//   padding-right: 100px;
//   font-size: 50px;
// }

// .ant-tabs > .ant-tabs-nav {
//   margin-bottom: 0 !important;
//   border-top: transparent !important;
// }

// .ant-tabs-content-holder {
//   border-top: transparent !important;
// }

// .inFilterButtonMobile {
//   display: none !important;
// }

// .inFilterButtonText {
//   font-size: 16px !important;
//   padding-left: 5px !important;
//   color: rgb(0, 0, 0);

// }

// .emptyImage{
//   display: flex;
//   justify-content: center;
//   width: max-content;
//   height: max-content;
// }

// .inFilterButtonTextClicked {
//   font-size: 16px !important;
//   padding-left: 5px !important;
//   color: rgb(255, 252, 252);

// }

// .filterIconCliked{
//   color: white;
// }

// .card-container {
//   justify-content: space-between;
//   display: flex !important;
//   border-top: transparent !important;
// }

// .ant-tabs-tab-btn{
//   font-weight: bold;
//   color: rgb(10, 114, 183) !important;

// }

// .showFilterComponent {
//   width: 100% ;
//   padding-bottom: 20px;
// }


// .ant-modal-root {
//     display: none !important;
// }

// .showFilterComponentMobile {
//  display: none !important;

// }



// @media only screen and (max-width: 768px) {

//   .ant-modal-root {
//     display: block !important;
//   }

//   .inFilterButton {
//     display: none !important;
//   }

//   .showFilterComponent {
//     display: none !important;
//   }

//   .filterButtonUnClicked {
//     height: 10px !important;
//   }

 

//   .inFilterButtonMobile {
//     display: flex !important;
//     align-items: center;
//     justify-content: center;
//     height: 40px !important;
//     border-radius: 8px !important;
//     margin: 10px;
//     border: solid 1px #939393 !important;
//     width: max-content;
//     min-width: 50px;
//     color: rgb(0, 0, 0) !important;
//   }

//   .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
//     height: 30px;
//   }

//   .ant-tabs-nav-wrap{
//     padding-top: 8px !important;
//   }

//   .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
//     display: flex !important;
//     font-size: 12px !important;
//     align-content: flex-start !important;
//   }

//   .ant-tabs-tab {
//     display: flex !important;
//     align-content: flex-start !important;
//   }

//   .peopleTabContentMobile {
//     padding-left: 20px;
//     padding-right: 20px;
//   }

//   .peopleTab {
//     display: flex !important;
//     flex-wrap: wrap;
//     grid-gap: 10px;
//     align-items: center;
//     justify-content: space-between;
//   }
// }
