@import '/src/Variable.scss';

.mainLayout {
  background-color: $gray-bg !important;
}

.banner {
  position: relative;
  background-color: #f0f0f0;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow: hidden; /* Add overflow property to hide overflowing content */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  height: calc(100vh - 46px);
}

.avatar {
  position: absolute;
  bottom: -50px; /* Move the avatar up by the desired amount */
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.form-container {
  margin-top: 150px; /* Adjust the margin according to your needs */
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.form-container form {
  /* Your form styles here */
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 700;
  // margin-bottom: 10px;
}

.second-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px !important;
}

.rating-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 26px;
  /* identical to box height */
  margin-top: 10px;
  margin-bottom: 5px;
  color: #000000;
}

.input-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-type {
  border-radius: 5px !important;
}

.rating-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 54px;
  height: 51px;
  // background: #fff2c9;
  background: #d9d9d9;
  border-radius: 10px;
  color: #000000;
}

.rating-box-checked {
  background: #fff2c9;
}

.rating-box-unchecked {
  background: #d9d9d9;
}

.rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 10px;
}

.rating-definition {
  display: flex;
  justify-content: space-between;
  color: #828282;
  font-size: 16px !important;
}

.rating-comment-box {
  // width: 805px !important;
  // height: 319px !important;
  background: #ffffff !important;
  border: 1px solid #b9b9b9 !important;
  border-radius: 10px !important;
}

.submit-button-wrapper {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.content-wrapper {
  padding: 20px;
  margin-bottom: 50px !important;
}

.submit-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  color: #ffffff;
}

.comment-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 22px;
  margin-top: 30px !important;
}

.rating-comment-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Default styles for normal screens */
.submit-button {
  width: 200px; /* Adjust the width as needed */
  // background: #005993 !important;
  border-radius: 10px !important;
  color: #fff;
  height: 50px !important;
}

.degree {
  font-size: 18px;
}

.designation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #005993;
}

.designation-details {
  position: absolute;
  bottom: -170px; /* Move the avatar up by the desired amount */
  left: 50%;
  transform: translateX(-50%);
}

.course-name {
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 30px !important;
  color: #000000;
}

.notification {
  background: #019c58;
  color: #fff;
}

@media (max-width: 767px) {
  .rating-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px;
    /* identical to box height */
    margin-top: 10px;
    margin-bottom: 5px;
    color: #000000;
  }
  /* Styles for mobile screens */
  .submit-button {
    width: 100%; /* Full width for mobile */
    // background: #005993 !important;
    border-radius: 10px !important;
    color: #fff;
    height: 50px !important;
    // border-color: none !important;
  }

  .degree {
    font-size: 16px !important;
  }

  .designation {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 16px !important;
    font-weight: bold;
    color: #005993;
  }

  .course-name {
    display: flex;
    justify-content: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 25px !important;
    color: #000000;
  }

  .designation-details {
    position: absolute;
    bottom: -170px !important; /* Move the avatar up by the desired amount */
    left: 50%;
    transform: translateX(-50%);
  }

  .comment-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px !important;
    margin-top: 30px !important;
  }
}
