@import '/src/Variable';

.sharePageModelTitle {
  width: 100% !important;
  display: flex !important; 
  margin-bottom: 10px;
  padding-top: 15px;
    padding-left: 7px;
}

.ant-modal-header{
  border-bottom: 1px solid #d9d9d9 !important;
}

.ant-modal-footer{
  border-top: 1px solid #d9d9d9 !important;
  padding: 16px 24px !important;
}

.ant-ribbon-corner{
  display: none !important;
}

ant-ribbon-text {
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 500 !important;;
  line-height: 19px !important;;
  letter-spacing: 0em !important;;
  text-align: center !important;;

}
.ant-ribbon.ant-ribbon-placement-end{
  right: auto !important;
  top: 16px;
  left: 16px;
  border-radius: $border-radius-sm;
}

.sharePageModel{
  
  padding-left: 8px;
  padding-right: 8px;
}

.sharePageModelLayout {
  display: flex;
  justify-content: center;
  // height: 400px !important;
  // width: 500px !important;
}

.sharePageText{
  font-size: 19px ;
  font-weight: bold;
}

.emailToText{
  font-size: 15px;
  color: black;
  margin-bottom: 10px;
  

}

.shareprofileDivider{
  width: max-content !important;
}

.emailToTextBox{
  margin-bottom: 10px;
}

.shareProfileButton{
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-color: #ffffff !important;
  border: none;
  box-shadow: none;
  border-radius: 6px !important;
  background-color: rgb(2,90,148)!important;
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.shareProfileFooter{
  display: flex !important;
  justify-content: flex-end !important;
}

.shareprofileText{
  font-size: small;
}

.ReactTags__tagInput {
  .ReactTags__tagInputField {
    border: unset;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    text-indent: 10px;
    width: 100%;
  }
}
.react-tags-wrapper {
  display: flex;
  flex-direction: column-reverse;
  .ReactTags__selected {
    .ReactTags__tag {
      background-color: #ACDDDE;
      border-radius: 100px;
      padding: 10px 12px 10px 20px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      margin: 10px 10px 0 0;
      .ReactTags__remove {
        background-color: unset;
        border: unset;
        color: #000;
        font-size: 22px;
        font-weight: 400;
        margin: 0 0 0 7px;
        cursor: pointer;
      }
    }
  }
}

