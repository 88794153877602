@import '/src/Variable';

.shareDropdownLayout{
    width: 163px !important;
    border-color: #141415;
    border-radius: 6px ; 
    .ant-menu{
        margin: 2px !important;
    }

    .shareDropdown{
    .dropDownShareButtonFb{
        height: 30px !important;
    }
    .dropDownShareButtonCopyUrl{
        background-color: transparent !important;
    }

    }
}

.badgesPopover {
    width: 600px;

    img {
         width: 48px;
         height: 48px;
         border-radius: 100%;
   }
   .badgesPopoverBadge {
        text-align: center;
        margin: 0 4px;
        display: inline-block;
        min-width: 100px;
   }
}

.viewProfile {
    
    .ant-row {
        .backButton {
            margin: 20px 0 0 120px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            border: unset;
            z-index: 2;
            svg {
                font-size: 24px;
            }
        }
        .bannerWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 215px;
            // overflow: hidden;
            position: relative;
            z-index: 1;
            .bannerImgWrapper {
                width: 100%;
                height: 215px;
                overflow: hidden;
                .backroundCover {
                    width: 100%;
                    // object-fit: cover;
                }
            }
            &:after {
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
                width: 100%;
                position: absolute;
                height: 110px;
                bottom: 0;
            }
            .avatarRow {
                display: flex;
                align-items: center;
                margin: -110px 50px 0 50px;
                position: absolute;
                left: 70px;
                bottom: -55px;
                z-index: 2;
                .profileImage {
                    width: 170px;
                    height: 170px;
                    border: 3px solid $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 28px 0 0;
                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                }
                
                .profileDetails {
                    display: flex;
                    align-items: center;
                    position: relative;
                    .profileName {
                        font-size: $font-xxl;
                        font-weight: 700;
                        color: $white;
                        text-shadow: 2px 2px 5px $black;
                    }
                    .ant-ribbon-wrapper {
                        .profileBadge {
                            background-color: $green;
                            border-radius: 2px;
                            position: unset;
                            margin: 0 0 0 7px;
                            .ant-ribbon-text {
                                color: $white;
                                font-size: 12px;
                            }
                        }
                    }
                    .profileEarnedBadges {
                        position: absolute;
                        top: 4.5rem;
                        left: 6rem;
                        display: flex;

                        img {
                            width: 48px;
                            height: 48px;
                            border-radius: 100%;
                            margin-right: -12px;
                            -webkit-transition: all ease .3s 0s;
                            transition: all .3s ease 0s;
                            border: 1px solid $white;
                        }
                        img:nth-child(1) {
                            z-index: 4;
                        }
                        img:nth-child(2) {
                           z-index: 3;
                        }
                        img:nth-child(3) {
                          z-index: 2;
                        }
                        .moreBtn {
                          z-index: 1;
                          width: 48px;
                          height: 48px;
                          border: 1px solid #dddddd;
                          background-color: $white;
                          color: #777777;
                          font-weight: bold;
                          cursor: pointer;
                          border-radius: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          margin-left: 0;
                        }
                    }

                    .profileEarnedBadges:hover {
                        img {
                            margin-right: -24px;
                        }
                    }
                }
            }
        }
        
    }
    
    .profileTab {
        margin: 0 0 0 0;
        .ant-row {
            .ant-col {
                .tabArea {
                    background-color: $gray-bg;
                    .ant-tabs-nav {
                        padding: 0 120px 0 320px;
                        background-color: $gray-light;
                        height: 75px;
                        margin: 0 0 37px 0;
                        .ant-tabs-nav-wrap {
                            position: relative;
                            z-index: 2;
                            .ant-tabs-nav-list {
                                .ant-tabs-tab {
                                    padding: 16px 10px;
                                    .ant-tabs-tab-btn {}
                                    &.ant-tabs-tab-active {
                                        .ant-tabs-tab-btn {
                                            font-size: 18px;
                                            font-weight: 700;
                                            color: $primary;
                                        }
                                    }
                                }
                                .ant-tabs-ink-bar {
                                    background-color: $primary;
                                }
                               
                            }
                        }
                       
                        .ant-tabs-extra-content {
                            .btnArea {
                                display: flex;
                                align-items: center;
                                margin-right: 40px;
                                .btnShareProfile {
                                    border: 1px solid $primary;
                                    border-radius: $border-radius-sm;
                                    padding: 0 15px;
                                    height: 45px;
                                    display: flex;
                                    align-items: center;
                                    margin: 0 12px 0 0;
                                    font-size: 15px;
                                    font-weight: 700;
                                    svg {
                                        margin: 0 6px 0 0;
                                        margin-bottom: 2px ;
                                    }
                                    .anticon {
                                        svg {
                                            margin: 0 0 0 5px;
                                        }
                                    }
                                }
                                .sendMsgBtn {
                                    border: 1px solid $primary;
                                    border-radius: $border-radius-sm;
                                    padding: 0 15px;
                                    height: 45px;
                                    display: flex;
                                    align-items: center;
                                    font-size: 15px;
                                    font-weight: 700;
                                    svg {
                                        margin: 0 5px 0 0;
                                    }
                                }
                            }
                        }
                    }
                    .ant-tabs-content-holder {
                        padding: 0 120px 50px 120px;
                        .ant-tabs-content {
                            .ant-tabs-tabpane {
                                .LeftCol {
                                    border: 1px solid $border-gray;
                                    border-radius: 6px;
                                    background-color: $white;
                                    .ratedColumn {
                                        padding: 29px 32px 10px 34px;
                                        .flexRow {
                                            display: flex;
                                            border: 1px solid $orange;
                                            background-color: $orange-light;
                                            border-radius: 6px; 
                                            height: 50px;
                                            .buddyColumn {
                                                display: flex;
                                                align-items: center;
                                                padding-left: 16px;
                                                width: 50%;
                                                img {
                                                    margin: 0 6px 0 0;
                                                }
                                                .ratingText {
                                                    display: flex;
                                                    align-items: center;
                                                    font-weight: 700;
                                                    color: $primary;
                                                    .rateCount {
                                                        margin: 0 5px 0 0;
                                                    }
                                                    .prospectResponse {

                                                    }
                                                }
                                            }
                                            .buddyReplyTime {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 50%;                                      
                                                font-weight: 700;
                                                color: $orange-dark;
                                            }
                                        }
                                    }
                                    .buddyProfileTable {
                                        padding: 0 32px 10px 34px;
                                        .tableInner {
                                            .ant-descriptions {
                                                .ant-descriptions-view {
                                                    table {
                                                        tbody {
                                                            tr {
                                                                min-height: 56px;
                                                                &:nth-last-child(1) {
                                                                    border-bottom: unset;
                                                                }
                                                                td {
                                                                    vertical-align: middle;
                                                                    padding: 10px 0;
                                                                    .ant-descriptions-item-container {
                                                                        .ant-descriptions-item-label {
                                                                            width: 165px;
                                                                            margin-right: 24px;
                                                                            font-weight: 500;
                                                                            padding-left: 0;
                                                                            &:after {
                                                                                display: none;
                                                                            }
                                                                        }
                                                                        .ant-descriptions-item-content {
                                                                            padding-right: 16px;
                                                                            .lang {
                                                                                &:after {
                                                                                    content: ',';
                                                                                    margin-right: 5px;
                                                                                }
                                                                                &:nth-last-child(1) {
                                                                                    &:after {
                                                                                        display: none;
                                                                                    }
                                                                                }
                                                                            }
                                                                            .hobbyIn {
                                                                                display: flex;
                                                                                align-items: center;
                                                                                .hobbyIcon {
                                                                                    img {
                                                                                        height: 25px;
                                                                                    }
                                                                                    &:after {
                                                                                        content: ',';
                                                                                        margin-right: 5px;
                                                                                    }
                                                                                    &:nth-last-child(1) {
                                                                                        &:after {
                                                                                            display: none;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                            .userLangugeImg {
                                                                                display: flex;
                                                                                align-items: center;
                                                                                img {
                                                                                    height: 30px;
                                                                                    margin-right: 5px;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .sendMsgBtnSp {
                                    display: none;
                                }
                                .rightCol {
                                    border: 1px solid $border-gray;
                                    border-radius: 6px;
                                    background-color: $white;
                                    .ratedColumn {
                                        padding: 29px 32px 10px 34px;
                                        height: 446px !important; /* Adjust the value as needed */
                                        overflow-y: auto !important;
                                        .rateTitle {
                                            font-size: 20px !important;
                                            font-weight: 700 !important;
                                        }
                                    }
                                }                             
                            }
                        }
                    }
                }
            }
        }
    }
}
.d-none {
    display: none !important;
}
@media only screen and (max-width: 768px) {
    .viewProfile {
        .ant-row {
            .backButton {
                width: 24px;
                min-width: 24px;
                height: 24px;
                margin: 13px 0 0 20px;
                svg {
                    font-size: 18px;
                }
            }
            .bannerWrapper {
                height: 92px;
                .bannerImgWrapper {
                    height: 92px;
                }
                .avatarRow {
                    margin: -110px 50px 0 20px;
                    left: 0;
                    .profileImage {
                        width: 87px;
                        height: 87px;
                        border: 2px solid #fff;
                        margin-right: 12px;
                    }
                    .profileDetails {
                        margin: 11px 0 0 0;
                        flex-direction: column;
                        align-items: flex-start;
                        .profileName {
                            font-size: 17px;
                            color: $black;
                            text-shadow: unset;
                            margin: 15px 0 0 0;
                        }
                        .ant-ribbon-wrapper {
                            .profileBadge {
                                margin: 0;
                                background-color: unset;
                                border: 1px solid $green;
                                min-width: 42px;
                                height: 14px;
                                padding: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .ant-ribbon-text {
                                    font-size: 8px;
                                    color: $green;
                                }
                            }
                        }
                    }
                }
            }
        }
        .profileTab {
            .ant-row {
                .ant-col {
                    .tabArea {
                        .ant-tabs-nav {
                            margin: 0 0 20px 0;
                            padding: 0 0 0 20px;
                            align-items: flex-start;
                            height: 93px;
                            .ant-tabs-nav-wrap {
                                .ant-tabs-nav-list {
                                    padding-top: 60px;
                                    .ant-tabs-tab {
                                        &.ant-tabs-tab-active {
                                            .ant-tabs-tab-btn {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                            .ant-tabs-extra-content {
                                .btnArea {
                                    margin: 14px 0 0 0;
                                    .sendMsgBtn {
                                        display: none;
                                    }
                                    .btnShareProfile {
                                        border: unset;
                                        margin-right: 0;
                                        height: 20px;
                                        .dropDownIcon {
                                            span {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .ant-tabs-content-holder {
                            padding: 0 20px 20px 20px;
                            .ant-tabs-content {
                                .ant-tabs-tabpane {
                                    .LeftCol {
                                        .ratedColumn {
                                            padding: 12px 12px 12px 12px;
                                            .flexRow {
                                                border: unset;
                                                .buddyColumn {
                                                    display: block;
                                                    min-width: 110px;
                                                    width: auto;
                                                    background-color: $gray-bg;
                                                    border-radius: 5px;
                                                    margin-right: 5px;
                                                    padding: 12px 7px 12px 11px;
                                                    img {
                                                        display: none;
                                                    }
                                                    .ratingText {
                                                        display: block;
                                                        .rateCount {
                                                            font-size: 12px;
                                                            line-height: 14px;
                                                        }
                                                        .prospectResponse {
                                                            font-size: 10px;
                                                            font-weight: 400;
                                                            color: $black-500 !important;
                                                        }
                                                    }
                                                }
                                                .buddyReplyTime {
                                                    background-color: $gray-bg;
                                                    border-radius: 5px;
                                                    width: 100%;
                                                }
                                            }
                                        }
                                        .buddyProfileTable {
                                            padding: 0 10px 3px 10px;
                                            .tableInner {
                                                .ant-descriptions {
                                                    .ant-descriptions-view {
                                                        table {
                                                            tbody {
                                                                tr {
                                                                    min-height: 34px;
                                                                    td {
                                                                        padding: 10px 0;
                                                                        .ant-descriptions-item-container {
                                                                            .ant-descriptions-item-label {
                                                                                font-size: 12px;
                                                                                padding-left: 6px;
                                                                                margin-right: 6px;
                                                                                width: 105px;
                                                                            }
                                                                            .ant-descriptions-item-content {
                                                                                font-size: 12px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .sendMsgBtnSp {
                                        background-color: $primary;
                                        width: 100%;
                                        height: 26px;
                                        color: $white;
                                        border-radius: $border-radius-sm;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 12px;
                                        font-weight: 400;
                                        margin: 18px 0 0 0;
                                        svg {
                                            margin: 0 5px 0 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ratingSection {
        margin-top: 30px;                          
    }
}




















// .ant-avatar {
//   border-color: red !important;
//   border: 12px !important;
// }


// .ant-layout-header {
//   background: #ffffff;
// }


// .languge {
//   display: flex;
//   width: max-content;
//   min-width: 70px;
//   justify-content: space-between;
// }

// .backroundCover {
//   object-fit: cover;
//   display: flex;
//   width: 100%;
//   height: 280px;
// }

// .backButton {
//   position: absolute;
//   padding: 30px;
// }

// .inBackButton {
//   display: flex;
//   padding-top: 7px !important;
//   align-items: center;
//   justify-content: center;
// }

// .profileImage {
//   border: 5px !important;
//   border: solid 3px white !important;
//   position: absolute !important;
//   z-index: 2 !important;
//   top: 150px !important;
//   left: 50px !important;
//   width: 180px !important;
//   height: 180px !important;
// }

// .profileDetails {
//   display: flex;
//   margin-left: 80px;
//   width: 50%;
//   padding-left: 150px;
//   padding-top: 220px;
//   position: absolute;

//   color: #ffffff;
// }

// .profileName {
//   display: flex;
//   justify-content: baseline;
//   height: 100%;
//   font-size: 40px;
//   color: #ffffff !important;
//   font-family: "Roboto", sans-serif;
// }

// .profileBadgeLayout {
//   padding-left: 70px;
//   padding-bottom: 30px;
// }

// .profileBadge {
//   display: flex;
//   width: 65px;
//   font-size: small !important;
//   justify-content: center;
// }

// .profiledetailsIn {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: space-evenly !important;
// padding-left: 10px;
// }

// .shareNavBarBuddyProfile {
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// }

// .dropDownProfileButton {
//   display: flex;
//   padding-right: 10px;
//   padding-top: 5px;
//   align-items: center !important;
//   width: max-content;
//   height: 100%;
//   border-radius: 5px !important;
//   border: solid 1px ;

//   padding-left: 15px;
//   padding-bottom: 5px;
//   margin-right: 10px;
// }

// .btnShareProfile{
//   display: flex;
//   align-items: center;
// }

// .sendMessageBtnStatus {
//   display: none;
// }

// .ant-modal-root {
//   display: block !important;
// }

// .profileTab {
//   width: 100%;
//   border: none !important;
//   position: static !important;
//   display: flex;
//   margin-top: 20px;
//   padding: 20px;
//   padding-left: 80px;
// }

// .leftColumnWidget {
//   display: flex;
//   margin-right: 80px;
// }

// .leftColumnBackround {
//   padding-bottom: 550px;
//   padding-left: 60px;
//   margin-left: 100px;
// }

// .leftColumn {
//   display: flex;
//   justify-content: flex-end;
// }

// .buddyRatedColumn {
//   border-radius: 8px !important;
//   border: solid 1px black !important;
// }

// .buddyRatedColumnIn {
//   border-radius: 8px !important;
//   border: solid 1px black !important;
//   display: flex;
//   width: 100%;
//   margin: 10px;
//   justify-content: center;
// }

// .buddyRatedTopPanel {
//   display: flex;
//   justify-content: center;
//   padding-top: 10px;
// }

// .buddyRating {
//   display: flex;
//   color: rgb(0,89,147);
// }

// .buddyRatedColumnIn {
//   display: flex;
//   align-items: center;
// }

// .buddyRatingLayout {
//   display: flex;
//   width: 100%;
//   justify-content: center;

//   align-items: center;
//   height: 20px;
// }

// .buddyReplyTime {
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   height: 20px;
// }

// .profileTab {
//   display: flex;
// }

// .buddyProfileTableBackround {
//   display: flex;
//   width: 100%;
//   padding: 15px;
// }

// .buddyProfileTable {
//   display: flex;
//   width: 100%;
//   border: none;
// }

// .buddyProfileTableIn {
//   background-color: #ffffff;
//   outline: none !important;
//   border-bottom: none !important;
// }

// .buddyRatedIcon {
//   margin-right: 5px;
//   margin-bottom: 5px;
// }

// .icon1 {
//   padding-right: 10px;
// }

// .icon2 {
//   padding-right: 10px;
// }

// .icon3 {
//   padding-right: 10px;
// }

// // .inUserLangugeImg {
// //   padding-right: 5px;
// // }

// .buddyRatingIn {
//   padding-right: 5px;
// }

// .shareButtonMobile {
//   display: none;
// }

// .sendMessageButtonViewProfile {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
//   border-color: #ffffff !important;
//   border: none;
//   box-shadow: none;
//   border-radius: 5px !important;
//   background-color: rgb(0,89,147) !important;
// }

// .shareButtonMobile{
//   display: none !important;
// }

// .sendMessageButtonViePro {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
//   border-color: #ffffff !important;
//   border: none !important;
//   box-shadow: none !important;
//   border-radius: 5px !important;

// }

// .sendMessageButtonText {
//   font-size: 14px;
//   padding-left: 5px;
// }

// @media only screen and (max-width: 768px) {
//   .hideSm {
//     display: none;
//   }
//   .sendMessageButtonMobile {
//     width: 100%;
//     margin-top: 10px;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     border-color: #ffffff !important;
//     border: none;
//     box-shadow: none;
//     border-radius: 5px !important;
//     background-color: rgb(0,89,147) !important;
//   }
//   .buddyRatedColumnIn {
//     display: none;
//   }

//   .buddyRatedTopPanelMobile {
//     display: flex;
//     width: 100%;
//     justify-content: flex-start;
//   }

//   .buddyRatingMobile {
//     display: flex;
//     flex-direction: column;
//   }

//   .sendMessageBtnStatus {
//     display: block;
//   }

//   .buddyReplyTimeMobile {
//     display: flex;
//     width: 100%;
//     justify-content: center;
//     align-items: center;
//     height: 20px;
//   }

//   .buddyRatingMobilein {
//     display: flex;
//     justify-content: flex-start;
//   }

//   .buddyRatedColumnInMobile {
//     display: flex;
//     text-align: end;
//     width: 150px;
//     height: 80px;
//     background-color: darkgray;
//     border: none !important;
//   }

//   .buddyRatedIcon {
//     display: none;
//   }

//   .divider {
//     display: none !important;
//   }

//   .leftColumnBackround {
//     display: none !important;
//   }

//   .leftColumnBackround {
//     display: none;
//   }

//   .profileDetails {
//     display: none;
//   }

//   .profileImage {
//     left: 5px;
//   }

//   .headerTopRightPanel {
//     display: none;
//   }

//   .profileTab {
//     padding-left: 10px;
//   }

//   .prospectResponse {
//     color: black;
//   }
//   .backroundCover {
//     object-fit: cover;
//     display: flex;
//     width: 100%;
//     height: 150px;
//   }

//   .buddyReplyTime {
//     display: none;
//   }

//   .profileImage {
//     border: 5px !important;
//     border: solid 3px white !important;
//     position: absolute !important;
//     z-index: 2 !important;
//     top: 80px !important;
//     left: 10px !important;
//     width: 120px !important;
//     height: 120px !important;
//   }

//   .profileTabIN {
//   }

//   .backButton {
//     position: absolute;
//     padding: 10px;
//   }

//   .profileDetailsMobile {
//     display: flex;
//     flex-direction: column;
//     padding-left: 130px;
//     padding-top: 150px;
//   }

//   .profiledetailsInMobile {
//     flex-direction: column;
//     width: 100%;
//   }

//   .profileName {
//     display: flex;

//     height: 35px;
//     font-size: 30px;
//     color: #000000 !important;
//     font-family: "Roboto", sans-serif;
//     padding-right: 50px;
//   }

//   profileNameIn {
//     display: flex !important;
//     width: 300px !important;
//   }

//   .ant-tabs-tab-btn {
//     font-size: 15px !important;
//     font-weight: bold;
//   }

//   .profileBadgeLayoutMobile {
//     padding-right: 80px;
//     padding-bottom: 20px;
//     margin-bottom: 20px;
//   }

//   .profileBadgeMobile {
//     display: flex;
//     width: 65px;
//     font-size: small !important;
//     justify-content: center;
//     padding-bottom: 20px !important;
//   }

  

//   .shareButtonMobile {
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     border: none !important;
//     border-color: #ffffff !important;
//     box-shadow: none;
//     left: 75px;
//     top: 5px;
//   }

//   .shareButtonMobileIcon {
//     color: rgb(0,89,147);
//   }
// }
