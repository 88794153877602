@import '/src/Variable.scss';

.home_page {
  .jumbotron {
    display: flex;
    align-items: center;
    .jumb-container {
      h2 {
        font-weight: 700;
        font-size: $font-xl;
        line-height: 30px;
        margin: 0;
      }
      p {
        font-weight: 400;
        font-size: 25px;
        line-height: 20px;
        margin: 5px 0 0 0;
      }
    }
  }
  .bannerHeader {
    padding: 24px 0;
  }
}

.jumb-container {
  padding: 20px 120px;
}

.bannerText {
  color: #fafafa;
  font-size: 30px;
}

.bannerSubText {
  color: #fafafa !important;
  font-size: $font-md !important;
}

.bannerHeader {
  padding: 30px;
  padding-left: 50px;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .home_page {
    .jumbotron {
      min-height: 81px;
      .jumb-container {
        padding: 20px;
        h2 {
          font-size: 17px;
          line-height: normal;
          padding-left: 0;
        }
        p {
          line-height: 14px;
        }
      }
    }
  }
  
  .bannerHeaderTextMobile {
    display: flex !important;
    font-size: 19px !important;
    font-weight: bold !important;
    padding: 0% !important;
  }

  .bannerHeaderSubTextMobile {
    display: flex !important;
    font-size: 12px !important;
    padding: 0% !important;
  }

  .jumb-containerMobile {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .bannerHeaderMobile {
    // display: none;
  }
}

@media only screen and (min-width: 1440px) {
  .ant-layout-content {
    // max-width: 1366px;
  }
}
