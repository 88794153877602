@import '/src/Variable.scss';

.referralCode {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.react-tel-input .form-control{
  border-color: $border-gray !important;
  width: 100% !important;
}

.textarea.ant-input {
  width: 100% !important;
}

.ant-divider-horizonta{
  display: none !important;
}

.yourNameInputIn {
  border-radius: 4px !important;
}

.yourMobileNumberIn {
  border-radius: 4px !important;
}

.yourEmailAddressIn {
  border-radius: 4px !important;
}

.yourEmailAddressText {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}
.yourMobileNumberText {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

.btnReferralCode {
  background-color: white;
  border: none;
}

.btnReferralCodeIn {
  display: flex;
  align-items: center;
}

.ferralCodeDropDownIcon {
  color: gray;
}

.referralCodeInput {
  display: flex;
  border: solid black 1px !important;
  border-radius: 5px !important;
  width: 200px !important;
}
.referralCodeLayout {
  display: flex;
  flex-direction: column;
}
.yourMobileNumberInput {
  .PhoneInputInput {
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 5px;
  }
}
.inputErrorMessage {
  color: #ff4d4f;
}
.ant-steps-item-finish {
  .ant-steps-item-icon {
    background-color: #1890ff !important;
  }
  .ant-steps-icon {
    color: #ffffff !important;
  }
}

@media only screen and (max-width: 768px) {

}
