@import '/src/Variable.scss';

.sayHelloPopupImg {
  display: block;
  margin-right: 10px;
}

.sayHelloPopupImgIn {
  border-radius: 60% !important;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: solid 3px white;
}

.sayHelloPopupTitle {
  display: flex;
  justify-content: flex-start !important;
}

.textArea {
  display: flex !important;
  position: relative;
  flex-direction: column;
  margin-bottom: 24px;
}

.textAreaIn {
  position: absolute !important;
  display: flex;
  width: 100% !important;
  height: 105% !important;
  resize: none;
  border-radius: 10px !important;
}

.textAreaDividerIn {
  position: absolute !important;
  margin-top: 250px !important;
}

.aiButtonLayoutRow {
  margin-top: 288px;
  .ant-btn {
    float: left;
  }
}

.aiButtonLayoutRow2 {
  display: flex;
}

.aiButton {
  margin: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 5px !important;
  color: rgb(0,89,147) !important;
}

.sayHelloPopupTitleText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.aiButtonHintLayout {
  //position: absolute;
  //padding-left: 20px;
  //padding-top: 260px;
  //color: darkgrey;
}

.sayHelloPopupTitleTextIn {
  color: black;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: flex-start;
}

.sayHelloPopupTitleSubTextIn {
  color: #9e9e9e;
  font-size: 15px;
  font-weight: 300;
}

.yy {
  padding-top: 100px;
}

//.ant-steps-item-container {
//  display: none !important;
//}

.sayHelloPopuBackButton {
  display: flex !important;
  align-items: center !important;
  border-radius: $border-radius-sm !important;
}

.checkBoxMainText {
  padding-right: 20px;
}

.notifyCheckBox {
  margin-top: 20px;
  display: flex;
}

.textAreaHeader {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}

.messageHeading {
  display: flex;
  color: black;
  font-size: 15px;
}

.privacyText {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #9e9e9e;
}
.steps-action {
  width: 100%;
  .CheckboxText {
    text-align: left;
  }
}

.btnClose {
  border-radius: $border-radius-sm !important;
  background-color: #ffffff !important;
}

.btnContinue {
  border-radius: $border-radius-sm !important;
  display: flex !important;
  align-items: center;
}

.modalStepsActions {
  display: flex;
  justify-content: end;
}

.sayHelloPopupLayout {
  width: 670px !important;
  display: flex;
}

.ant-modal-content {
  width: 100% !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.ant-modal-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.btnSendMessage {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: $border-radius-sm !important;
  .sendMessageButtonText {
    padding-left: 4px;
  }
}

.secondStepFooter {
  display: flex;
  justify-content: space-between;
}

.btnContinueLayout {
  margin-left: 10px;
}

.ant-modal-close {
  margin-top: 15px !important;
  margin-right: 40px !important;
  color: $black-500 !important;
}

.ant-modal-close-x {
  height: 10px !important;
  width: 10px !important;
  font-size: 20px !important;
  color: $black-500;

  .anticon-close {
    background-color: $gray-bg;
    border-radius: 100px;
    padding: 8px;
    svg {
      font-size: 12px;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: rgb(0,89,147) !important;
  border-color: rgb(0,89,147) !important;
}

.termsOfUseText {
  text-decoration: underline;
}

.privacyPolicyText {
  text-decoration: underline;
}

.chatRulesText {
  text-decoration: underline;
}

.chatRulesPopoverLayout {
  height: 150px !important;
  width: 260px !important;
}

.dosTextHeader {
  color: rgb(49, 174, 120);
  font-weight: bold;
  font-size: large;
}

.dosTextDescrip {
  color: black;
  font-size: small;
}

.dontsTextHeader {
  padding-top: 10px;
  color: rgb(219, 76, 65);
  font-weight: bold;
  font-size: large;
}

.dontsTextDescrip {
  color: black;
  font-size: small;
}

.ant-popover-inner {
  border-radius: 8px !important;
}

.btnContinueText {
  padding-left: 8px;
}

@media only screen and (max-width: 768px) {
  //   /* For mobile phones: */

  .ant-modal-close {
    margin-top: 12px !important;
    margin-right: 25px !important;
    color: rgb(77, 77, 77) !important;
  }
  .sayHelloPopupImgMobileIn {
    border-radius: 60% !important;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: solid 3px white;
  }

  .privacyText {
    display: none;
  }

  .CheckboxText {
    font-size: small !important;
  }

  .chatRules {
    display: flex;
  }


  .aiButtonHintLayout {
    display: none;
  }

  .aiButton1 {
    display: none !important ;
  }

  .textAreaIn {
    position: absolute !important;
    display: flex;
    width: 100% !important;
    height: 200px !important;
  }

  .sayHelloPopupLayout {
    display: flex;
    width: 670px !important;
    height: 100% !important;
    height: max-content !important;
  }

  .textAreaDividerIn {
    position: absolute !important;
    margin-top: 100px !important;
  }

  .aiButtonLayout {
    margin-top: 100px;
  }

  .CheckboxText {
    font-size: smaller;
  }

  .btnContinueText {
    font-size: smaller;
  }

  .notifyCheckBox {
    padding-top: 0px;
    display: flex;
  }

  .test1 {
    display: flex !important;
    width: 100% !important;
    justify-content: space-around !important;
  }

  .modalStepsActions {
    justify-content: space-between;
    margin-top: 4px;
  }
}
