@import '/src/Variable.scss';

.starMsgLayout{
    display: flex;
    flex-direction: row;
    // height: calc(100vh - 100px);
    width: 370px;
    .ant-spin-nested-loading {
        width: 100%;
    }
    .buddiesListHeader, .ant-page-header{
        background-color: $gray-bg;
        border: 0.6375px solid rgba($card-border, 0.25);
        border-top: unset;
        display: flex;
        align-items: center;
        width: 370px;
        padding: 20px 10px 17px 24px;
        min-height: 80px;
        max-height: 80px;
        .buddiesSearchInput{
            display: flex;
            border-radius: 7px;
            width: 294px;
            height: 42px;
            padding: 0 0 0 13px;
            text-indent: 20px;
            border: unset;
            input {
                border-radius: 7px;
            }
            .ant-input-prefix {
                svg {
                    color: $gray-text;
                    margin: 0 7px 0 0;
                }
            }
        }
        .menuButtonBuddie{
            display: flex;
            justify-items: center;
            box-shadow: none;
            border: unset;
            padding: 0;
            height: auto;
            margin: 0 0 0 auto;
            background: unset;
            .menuIconIn{
                color: black;
            }
        }
    }
    .myChatBuddiesView{
        background-color: $gray-light;
        // background-color: #ddd;
        display: flex;
        flex-direction: column;
        width: 100%;
        // height: calc(100vh - 220px);
        height: calc(100vh - 150px);
        overflow: auto;
        .staredMsgBody {
            .ant-list-items {
                .listLayout {
                    display: block;
                    min-height: 84px;
                    max-height: auto;
                    padding: 14px 24px;
                    align-items: flex-start;
                    border-bottom: 1px solid rgba($card-border, 0.35);
                    cursor: pointer;
                    position: relative;
                    &:hover {
                        background-color: rgba($card-border, 0.23);
                    }
                    .senderInfoArea {
                        .senderInfo {
                            font-size: 14px;
                            color: $black-500;
                            font-weight: 400;
                        }
                        .chatBubble {
                            border-radius: 0 6px 6px 6px;
                            padding: 10px 16px;
                            word-break: break-word;
                            background-color: $primary;
                            color: $white;
                            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
                            line-height: normal;
                            width: 90%;
                            &.reply {
                                background-color: $white;
                                color: $black;
                            }
                            .ant-image {
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .iconRemove {
                        position: absolute;
                        top: 17px;
                        right: 20px;
                        background-color: unset;
                        border: unset;
                        padding: 0;
                        height: auto;
                    }
                    .lastChatDate {
                        margin: 4px auto 0 0;
                        font-size: 11.5px;
                        color: $gray-text;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .buddyListLayoutSider {
        .ant-spin-nested-loading {
            width: 100%;
            .ant-spin-container {
                .buddiesListHeader {
                    width: 100%;
                }
                .myChatBuddiesView {
                    height: calc(100vh - 650px);
                }
            }
        }
    }
}






























// @media only screen and (max-width: 768px) {
//     .width_0px{
//         display: none !important;
//         background-color: green !important;
//     }

//     .buddyListLayoutSider{
//         display: flex;
//         flex-direction: row;
//         max-width: 100vw;
    
//     .selectedListBuddy {
//         backgroundColor:'#1890ff';
//         color:#fafafa;
//         borderRadius:10
//     }
//     .lastMessagePart{
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         color:#010101;  
        
    
//     }
    
//     .listLayout{
//         padding: 20px;
//         align-items: baseline;
//     }
    
//     .buddieName{
//         font-weight: bolder;
//         font-size: large;
//     }


       
//     }

//     .buddiesListHeader{
//         background-color: #f1eeee;
//         box-shadow: rgba(0, 0, 0, 0.2) 80px 80px 84px;
//         display: flex;
//         align-items: center;
//         height: 75px;
//         width: 100vw;
        
        
    
//     }
//     .myChatBuddiesView{
//         background-color: rgb(255, 255, 255);
//         display: flex;
//         flex-direction: column;
//         width: 100vw;
//         height: calc(100vh - 193px);
    
//         overflow: auto;
        
//     }    
// }
