.aiButton1 {
  margin: 3px !important;
  font-size: small !important;
  padding: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
border: solid 2px !important;
border-radius: 8px !important;
  // color: rgb(0,89,147) !important;
}

.aiButtonLayoutMain{
  margin-left: 15px;
}

.textAreaDividerIn{
  display: flex !important ;
  align-items: center !important;
justify-content: center !important;
  width: 10px !important;
  background-color: #d9d9d9 !important;
  left: 21px !important;
    min-width: 93% !important;
}

.secureIcon {
  color: rgb(37,170,112);
  padding-bottom: 2px;
 
}

.starIcon{
  color: rgb(255,71,71);
  margin-bottom: 7px;

}

.inUserChatText{
  padding-left: 5px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .aiButton1 {
    display: none !important;
  }
}
