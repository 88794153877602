.home-layout-wrapper {
  background-color: #ECECEC;
  display: flex;
  justify-content: space-between;
  // margin-top: 100px;
  .home-layout {
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    .univiserLogo {
      display: flex;
      max-width: 100px;
      max-height: 100px;
    }
    .copy {
      margin: 0 10px 0 0;
    }
  }
}

// .footer-clean {
//   padding: 50px 0;
//   background-color: #fff;
//   color: #4b4c4d;
// }

// .footer-clean h3 {
//   margin-top: 0;
//   margin-bottom: 12px;
//   font-weight: bold;
//   font-size: 16px;
// }

// .footer-clean ul {
//   padding: 0;
//   list-style: none;
//   line-height: 1.6;
//   font-size: 14px;
//   margin-bottom: 0;
// }

// .footer-clean ul a {
//   color: inherit;
//   text-decoration: none;
//   opacity: 0.8;
// }

// .footer-clean ul a:hover {
//   opacity: 1;
// }

// .footer-clean .item.social {
//   text-align: right;
// }

// @media (max-width: 767px) {
//   .footer-clean .item {
//     text-align: center;
//     padding-bottom: 20px;
//   }
// }

// @media (max-width: 768px) {
//   .footer-clean .item.social {
//     text-align: center;
//   }
// }

// .footer-clean .item.social > a {
//   font-size: 24px;
//   width: 40px;
//   height: 40px;
//   line-height: 40px;
//   display: inline-block;
//   text-align: center;
//   border-radius: 50%;
//   border: 1px solid #ccc;
//   margin-left: 10px;
//   margin-top: 22px;
//   color: inherit;
//   opacity: 0.75;
// }

// .footer-clean .item.social > a:hover {
//   opacity: 0.9;
// }

// @media (max-width: 991px) {
//   .footer-clean .item.social > a {
//     margin-top: 40px;
//   }
// }

// @media (max-width: 767px) {
//   .footer-clean .item.social > a {
//     margin-top: 10px;
//   }
// }

// .footer-clean .copyright {
//   margin-top: 14px;
//   margin-bottom: 0;
//   font-size: 13px;
//   opacity: 0.6;
// }

// .univiserLogo {
//   display: flex;
//   max-width: 100px;
//   max-height: 100px;
//   object-fit: contain;
//   padding-bottom: 10px;
// }

// .home-layout {
//   display: flex;
//   align-items: center;
//   width: 190px;
//   justify-content: space-between;
//   padding-left: 10px;
// }

// .home-layout-wrapper {
//   background-color: #d6d9dc;

//   display: flex;
 
//   padding-top: 10px;
//   justify-content: space-between;
// }

// .feedbackSupportLink {
//   padding-bottom: 10px;
// }

// .textIn {
//   color: blue;
// }

// @media only screen and (max-width: 768px) {

// .FooterLayoutMobile{
//   background-color: #d6d9dc;
 
//   display: flex;
  
//   padding-top: 10px;
//   justify-content: space-between;
// }
// }