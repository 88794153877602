@import '/src/Variable.scss';

.comment-list {
    height: 100%;
    .ant-spin-nested-loading {
        // height: 100%;
        height: 92%;
        .ant-spin-container {
            height: 100%;
            display: flex;
            .ant-list-items {
                width: 100%;
                margin-top: auto;
                .chatBubbleArea {
                    width: 100%;
                    .ant-row {
                        .chatBubbleLayout, .replyUserLayout {
                            display: flex;
                            flex-direction: column;
                            margin: 0 30px 15px auto;
                            max-width: 60%;
                            .replyBtn {
                                margin-left: auto;
                                font-size: 11.5px;
                                color: $black-500;
                                height: 22px;
                                padding: 0;
                                display: flex;
                                align-items: center;
                                svg {
                                    font-size: 14px;
                                    margin: 0 2px 0 0;
                                }
                            }
                            .chatBubble {
                                display: flex;
                                justify-content: flex-end;
                                margin-left: auto;
                                background-color: $primary;
                                border-radius: 6px;
                                padding: 10px 16px;
                                color: $white;
                                position: relative;
                                word-break: break-word;
                                a {
                                    word-break: break-all;
                                }
                                img {
                                    max-width: 600px;
                                    min-height: 125px;
                                    max-height: 200px;
                                    border-radius: 6px;
                                }
                                .starMsg {
                                    position: absolute;
                                    bottom: -2px;
                                    right: 3px;
                                    svg {
                                        font-size: 12px;
                                        cursor: pointer;
                                        &.star {
                                            color: $white;
                                            &.stared {
                                                color: $yellow;
                                            }
                                        }
                                    }
                                }
                            }
                            .chatBubbleTime {
                                margin: 3px auto 0 0;
                                font-size: 11.5px;
                                color: $gray-text;
                            }
                        }
                        .replyUserLayout {
                            margin: 15px;
                            margin: 0 auto 15px 30px;
                            .chatBubble {
                                background-color: $white;
                                color: $black;
                                .starMsg {
                                    position: absolute;
                                    bottom: -2px;
                                    right: 3px;
                                    svg {
                                        &.star {
                                            color: $black-400;
                                            &.stared {
                                                color: $yellow;
                                            }
                                        }
                                    }
                                }
                            }
                            .chatBubbleTime {
                                margin: 3px 0 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .comment-list {
        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-list-items {
                    .chatBubbleArea {
                        .ant-row {
                            .chatBubbleLayout {
                                max-width: 70%;
                                margin: 0 20px 4px auto;
                                .chatBubble {
                                    font-size: 13px;
                                    padding: 10px 14px;
                                }
                                .chatBubbleTime {
                                    margin: 3px 0 0 auto;
                                }
                                .chatBubbleTime {
                                    font-size: 11px;
                                }
                            }
                            .replyUserLayout {
                                margin: 0 auto 5px 20px;
                                .chatBubble {
                                    font-size: 13px;
                                    padding: 10px 14px;
                                }
                                .chatBubbleTime {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



















// .chatBubbleContentLayoutIn{
//     display: flex;
//     justify-content: flex-end ; 
//     width: max-content;
//     background-color:rgb(0,89,147)  !important ;   
//     border-radius: 10px;
//     padding: 20px;
// }

// .chatBubbleContent{
//     color: white ;
//     display: flex;
//     justify-content: flex-start;
//     margin-bottom: 0% !important;
// }

// @media only screen and (max-width: 768px) {
// .chatBubbleContentLayout{
//     display: flex;
//     justify-content: flex-end;

//     width: 88vw;
// }
// .chatBubbleTime{
//     color: rgb(116, 113, 113) ;
//     display: flex;
//     width: 100%;
//     justify-content: flex-end;
// }
// .replyLayout{
//     margin-top: 50px;
//     display: flex;
//     width: 95%;
//     justify-content: flex-end;
// }

// }