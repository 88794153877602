

.MyChatBuddiesView{
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100vmax;
}

.BuddiesListHeader{
    background-color: rgb(204, 204, 204);

}

.BuddiesSearchInput{
    display: flex;
    width: 80% !important;
    margin: 20px !important;
}

.listLayout{
   padding: 30px;
   align-items: baseline;
}